// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiHost: 'https://qac-eu.cemexgo.com/api/',
  apiOrg: '',
  apiEnv: '',
  env: 'qa',
  appCode: '',
  clientId: '',
  hostEurope: 'localhost:5000',
  hostAmerica: 'localhost:4200',
  siteDomain: '.cemexgo.com',
  countlyKey: 'b287fda9ffdf4d330741597ae2eff4cc47206260',
  countlyUrl: 'https://cemex.count.ly',
  showMaintenanceAlert: '',
  cmxLoyaltyProgramUrl: 'https://www.mycemex.cemex.com/',
  language: 'en_US',
  urlnocustomers: 'http://uscldcrrwad01.azurewebsites.net',
  translations: {
    translateApiUrl: 'https://uscldcnxwaadmq01.azurewebsites.net',
    translateAppName: 'user-provisioning',
    getLanguages: '/translate/getRegionalLanguages',
    getTranslation: '/api/translate/translate/',
    fallbackLanguage: 'en_US',
  },
  cobrowseKey: 'j6tCMcxsjbqNRQ',
};
